import { PartnerCustomerService } from './../components/partner-customer-dropdown/partner-customer.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PartnerCustomer } from '../models/partner-customers-info';

@Injectable({
  providedIn: 'root'
})
export class PartnerCustomerResolverService implements Resolve<Observable<PartnerCustomer[]>> {

  constructor(
    private partnerCustomerService: PartnerCustomerService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PartnerCustomer[]> {
    return this.partnerCustomerService.getPartnerCustomersInfo();
  }

}
