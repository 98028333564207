import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet>
    <div class="w-100 h-100 position-absolute d-flex flex-column justify-content-center align-items-center">
      <div>Loading...</div>
      <mat-progress-bar class="progress-bar" mode="indeterminate"></mat-progress-bar>
    </div>
    </router-outlet>
    `,
  styles: [
    `
    .progress-bar {
      width:250px !important;
    }
    `
  ]
})
export class AppComponent {
}
