import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmDialogData } from "../../../models/confirm-dialog-data";


@Component({
  selector: 'ra-app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) { }

  onCancel() {
    this.dialogRef.close(this.data?.cancelResponse || false);
  }

  onOk() {
    this.dialogRef.close(this.data?.okResponse || true);
  }

  onSave() {
    this.dialogRef.close(this.data?.saveResponse || true);
  }
}
