import { MatDialog } from '@angular/material/dialog';
import { AUTH_CONFIRM_POPUP_TIME_IN_MINS, DEFAULT_DIALOG_CONFIG } from './../constants/app-constants';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import * as decode from 'jwt-decode';
import { Auth0UserProfile, Auth0DecodedHash } from 'auth0-js';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { of, Subject, Subscription, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ConfirmDialogData } from 'projects/json-dynamic-form/src/lib/models/confirm-dialog-data';
import { ConfirmDialogComponent } from 'projects/json-dynamic-form/src/lib/shared/components/confirm-dialog/confirm-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private userProfile: any;
    private userName: any;
    private userEmail: any;
    private userId: any;
    private userRegion: string | undefined;
    private userRegionSet: string[] | undefined;
    private userRole: string | undefined;
    private userGroups: string[] | undefined;
    private Access: {
        accounts: { [key: string]: string },
        roles: { [key: string]: { name: string[], permissions: string } }
    } = {accounts: {}, roles: {}};

    private protocol = window.location.hostname === 'localhost' ? 'http' : 'https';
    private auth0 = new auth0.WebAuth({
        ...environment.auth,
        redirectUri: `${this.protocol}://${window.location.hostname}:${window.location.port}/login/complete`
    });
    private timer: any = null;
    private refreshSub: Subscription;

    private logoutObs = new Subject();
    logoutObs$ = this.logoutObs.asObservable();

    constructor(private router: Router, private http: HttpClient, private dialog: MatDialog) {
        this.parseToken(this.getAccessToken());
        this.startTimer();
        this.hasPermission();
    }

    public hasPermission() {
        const role = this.Access.roles.toString();
        if (role.indexOf('NA') > -1) {
            this.Access.roles = {role: {name: [role], permissions: 'NA'}};
        } else if (role.indexOf('EU') > -1) {
            this.Access.roles = {role: {name: [role], permissions: 'EU'}};
        } else {
            this.Access.roles = {role: {name: [role], permissions: ''}};
        }
    }

    public getUserRoles() {
        return this.Access.roles.role.name;
    }

    public getUserEmail() {
        return this.userEmail;
    }

    public getUserName() {
        return this.userName;
    }

    public getUserRole() {
        return this.userRole;
    }

    public getUserRegion() {
        return this.userRegion;
    }

    public getUserRegionSet() {
        return this.userRegionSet;
    }

    public getUserGroups() {
        return this.userGroups;
    }

    public initializeRegionRole() {
        const userRegionRole = {
            role: localStorage.getItem('userRole'),
            region: localStorage.getItem('userRegion'),
            regionSet: JSON.parse(localStorage.getItem('userRegionSet') || '')
          };
        localStorage.removeItem('userSpecifiedRegion');
        this.setUserRegionRole(userRegionRole);
    }

    public setUserRegionRole(data: any) {
        this.userRegion = data.region;
        this.userRole = data.role;
        this.userRegionSet = data.regionSet;
    }

    public setPermission() {
        const role = this.Access.roles;
        const roleName = this.Access.roles.toString();
        if (role.toString().indexOf('NA') > -1) {
            this.Access.roles = {role: {name: [roleName], permissions: 'NA'}};
        } else if (role.toString().indexOf('EU') > -1) {
            this.Access.roles = {role: {name: [roleName], permissions: 'EU'}};
        }
    }

    public async getProfile(): Promise<any> {
        if (this.userProfile) {
            return this.userProfile;
        }

        const accessToken: string = this.getAccessToken();
        if (!accessToken) {
            throw new Error('Access Token must exist to fetch profile');
        }

        const self = this;
        return new Promise<any>((res, rej) => {
            this.auth0.client.userInfo(accessToken, (err, profile) => {
                if (err) {
                    rej(err);
                } else {
                    self.userProfile = profile;
                    res(profile);
                }
            });
        });
    }

    public beginLogin(landing: string = '/'): void {
        this.auth0.authorize();
        localStorage.setItem('landing', landing);
    }

    public async completeLogin() {
        this.auth0.parseHash((err, result) => {
            if (err) {
                console.error(err);
                return;
            }
            const accessToken = result?.accessToken || '';
            if(accessToken) {
              this.auth0.client.userInfo(accessToken, (e, info) => {
                if (e) {
                    console.error(e);
                } else {
                    this.parseToken(accessToken);
                    this.storeAuth(result, info);
                    const landing = localStorage.getItem('landing') || '/home';
                    this.router.navigateByUrl(landing);
                }
            });
            }
        });
    }

    public logout() {
        this.clearTimer();
        this.unscheduleRenewal();
        localStorage.clear();
        const returnTo = encodeURI(`${window.location.protocol}//${window.location.host}`);
        window.location.assign(`https://${environment.auth.domain}/v2/logout?returnTo=${returnTo}&client_id=${environment.auth.clientID}`);
    }

    public isLoggedIn(): boolean {
        const token = this.getLocalStorageString('id_token');
        const expires = +this.getLocalStorageString('expires_at');
        return !!(token && expires > new Date().getTime());
    }

    public getAccessToken(): string {
        return this.getLocalStorageString('access_token');
    }

    public getIdToken(): string {
        return this.getLocalStorageString('id_token');
    }

    public getEmail(): string {
        return this.getLocalStorageString('email');
    }

    public getProfilePic(): string {
        return this.getLocalStorageString('picture');
    }

    public getExpiresIn(): number {
        const expires = +this.getLocalStorageString('expires_at');
        return Math.max((expires - new Date().getTime()) / 1000, 0);
    }

    private startTimer() {
        this.clearTimer();
        let timeLeft = this.getExpiresIn();

        if (!timeLeft) {
            return;
        }

        this.timer = setInterval(() => {
            timeLeft = this.getExpiresIn();
            if (timeLeft <= 0) {
              this.clearTimer();
              const data: ConfirmDialogData = {
                message: `Your session has expired. Login again to continue working?`,
                okButtonLabel: 'Login',
                title: 'Session Expired !',
                showCancelBtn: false,
                footnote: ''
              };
              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                ...DEFAULT_DIALOG_CONFIG,
                data
              });
              dialogRef.afterClosed().subscribe(res => {
                this.logout();
              });
            }
        }, 500);
    }

    private clearTimer() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

  public extendLogin() {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        console.error('Could not get a new token using silent authentication: \n' + err);
      } else {
        console.log('Extending the user session...');
        this.storeAuth(result);
      }
    });
  }

  scheduleRenewal(): void {
    this.unscheduleRenewal();
    if (!this.isLoggedIn()) {
      return;
    }

    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const source = of(expiresAt).pipe(
      mergeMap(expires => {
        const now = Date.now();
        const refreshAt = expires - (1000 * AUTH_CONFIRM_POPUP_TIME_IN_MINS * 60);
        return timer(Math.max(1, refreshAt - now));
      })
    );

    this.refreshSub = source.subscribe(() => {
      const data: ConfirmDialogData = {
        message: `The session will expire in ${AUTH_CONFIRM_POPUP_TIME_IN_MINS} minutes. Would you like to continue working?`,
        okButtonLabel: 'Extend Session',
        cancelButtonLabel: 'Log off',
        title: 'Session Expiring Soon !',
        showCancelBtn: true,
        footnote: ''
      };

      this.dialog.open(ConfirmDialogComponent, {
        ...DEFAULT_DIALOG_CONFIG,
        data: {
          ...data
        }
      }).afterClosed().subscribe(res => {
        if(res === true) {
          this.extendLogin();
        } else if (res === false) {
          this.logout();
        }
      });
    });
  }

  public unscheduleRenewal(): void {
    this.refreshSub?.unsubscribe();
  }

    private storeAuth(authResult: any, info?: Auth0UserProfile) {
        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);
        if(info) {
          localStorage.setItem('email', info?.email?.toLowerCase() || '');
          localStorage.setItem('name', info.name);
          localStorage.setItem('picture', info.picture);
        }
        this.startTimer();
    }

    private parseToken(token: string) {
        if (!token) {
            return;
        }

        const parsed = decode(token);
        this.Access.roles = parsed['https://ra.com/roles'] || {roles: {}};
        this.userGroups = parsed['https://ra.com/roles'];
        this.userName = parsed['https://ra.com/name'];
        this.userEmail = parsed['https://ra.com/email'];
    }

    private getLocalStorageString(key: string): string {
      return localStorage.getItem(key) || '';
    }

    emitLogout() {
      this.logoutObs.next();
    }
}
