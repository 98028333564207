import { PartnerCustomerResolverService } from './shared/resolvers/partner-customer-resolver.service';
import { AuthGuard } from './core/guards/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: 'home',
    canActivate: [AuthGuard],
    resolve:{
      partnerCustomerInfo: PartnerCustomerResolverService
    },
    loadChildren: () =>
      import('./home/home.module').then(
        module => module.HomeModule
      )
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(
      module => module.LoginModule
    )
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
