import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var regex = /s3\.amazonaws\.com/i;
        if(regex.test(request.url) == false) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getAccessToken()}`
                }
            });
        }
        return next.handle(request);
    }
}
