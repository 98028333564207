import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-pin-column',
  templateUrl: './pin-column.component.html',
  styleUrls: ['./pin-column.component.scss']
})
export class PinColumnComponent  {
  @HostBinding('class') classList = 'w-100 h-100';
  @Input() pinnedColumns = {};
  @Input() headerName = '';
  @Input() column = '';
  get columnName() {
    return this.column || this.headerName;
  } 

  constructor() { }

  togglePin(event: MouseEvent) {
    if (this.pinnedColumns[this.columnName]) {
      delete this.pinnedColumns[this.columnName];
    } else {
      this.pinnedColumns[this.columnName] = true;
    }
    event.preventDefault();
    event.stopImmediatePropagation();
  }

}
