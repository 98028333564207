import { environment } from 'src/environments/environment';
import { AngularMaterialModule } from './shared/modules/angular-material.module';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthService } from './core/services/auth.service';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faShapes, faFilm } from '@fortawesome/free-solid-svg-icons';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TokenInterceptor } from '@core/services/token.inteceptor';
import { EXTERNAL_CONFIG } from 'json-dynamic-form';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularMaterialModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      maxOpened: 1
    })
  ],
  providers: [
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: EXTERNAL_CONFIG,
      useValue: {
        api: environment.api
      }
    }
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    library.add(faShapes, faFilm)
  }
}
